$(function () {

    // search bar widget
    $.widget("custom.catcomplete", $.ui.autocomplete, {
        _create: function () {
            this._super();
            this.widget().menu("option", "items", "> :not(.ui-autocomplete-category)");
        },
        _renderMenu: function (ul, items) {
            var that = this,
                currentCategory = "";
            $.each(items, function (index, item) {
                var li;
                if (item.category != currentCategory) {
                    ul.append("<li class='ui-autocomplete-category'>" + item.category + "</li>");
                    currentCategory = item.category;
                }
                li = that._renderItemData(ul, item);
                if (item.category) {
                    li.attr("aria-label", item.category + " : " + item.label);
                }
            });
        }
    });

    $("#q").catcomplete({
        source: '/search/autocomplete',
        minLength: 1,
        delay: 0,
        select: function (event, ui) {
            window.location.href = ui.item.link;
        }
    });


    // trigger delete confirmation modal
    $('.btn-danger').on('click', function (e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        $('#confirm').modal({backdrop: 'static', keyboard: false})
            .on('click', '#delete-btn', function () {
                $form.submit();
            });
    });

    // trigger event confirmation modal
    $('.confirm-update').on('click', function (e) {
        e.preventDefault();
        var $form = $(this).closest('form');
        $('#confirm').modal({backdrop: 'static', keyboard: false})
            .on('click', '#delete-btn', function () {
                $form.submit();
            });
    });

    // table sorter widget
    $(".tablesorter").tablesorter({
        theme: 'bootstrap',

        dateFormat: "ddmmyyyy",

        headerTemplate: '{content} {icon}', // new in v2.7. Needed to add the bootstrap icon!

        // widget code contained in the jquery.tablesorter.widgets.js file
        // use the zebra stripe widget if you plan on hiding any rows (filter widget)
        widgets: ["uitheme", "zebra"],

        widgetOptions: {
            // using the default zebra striping class name, so it actually isn't included in the theme variable above
            // this is ONLY needed for bootstrap theming if you are using the filter widget, because rows are hidden
            zebra: ["even", "odd"]
        }

    });

    // initiate datepicker
    $.datepicker.setDefaults($.datepicker.regional["de"]);
    $(".datepicker").datepicker({
        changeMonth: true,
        changeYear: true,
        yearRange: "-100:+10"
    });

    function getDate(element) {
        var date;
        try {
            date = $.datepicker.parseDate(dateFormat, element.value);
        } catch (error) {
            date = null;
        }

        return date;
    }

    // add dynamic form field to survey
    var i = $('.form-field').length - 1;
    $('#addFormField').click(function () {
        i++;
        var $clone = $(".form-field").first().clone(true, true);
        $clone.attr("id", 'form-field-' + i);

        //rename ids and clear input
        $clone.find('label').each(function () {
            var self = $(this);
            self.attr("for", self.attr('for').replace('0', i));
        });
        $clone.find('select').each(function () {
            var self = $(this);
            self.attr("name", self.attr('name').replace('0', i)).val(null);
            self.attr("id", self.attr('id').replace('0', i)).val(null);
        });
        $clone.find('textarea').each(function () {
            var self = $(this);
            self.attr("name", self.attr('name').replace('0', i)).text('');
            self.attr("id", self.attr('id').replace('0', i)).text('');
        });
        $clone.find('input').each(function () {
            var self = $(this);
            self.attr("name", self.attr('name').replace('0', i)).val('');
            if (self.attr("id")) {
                self.attr("id", self.attr('id').replace('0', i)).val('');
            }
        });
        $clone.find('.btn_remove').each(function () {
            var self = $(this);
            self.attr("id", self.attr('id').replace('0', i)).val('');
        });

        //append to form-fields
        $clone.appendTo(".form-fields");
    });

// remove form field
    $('.form-fields').on('click', '.btn_remove', function () {
        var button_id = $(this).attr("id");
        $('#form-field-' + button_id + '').remove();
    });
})
;
